.drawerDiv {
    margin: 22px 30px 20px;
  }
  
  .drawerCard {
    > div {
      padding: 15px !important;
    }
  }
  
  .ProcessTable {
    > thead {
      width: 20%;
      padding: 16px;
    }
  }
  
  .ProcessTable {
    > thead > tr > th {
      width: 20%;
      padding: 16px;
      border: 1px solid #ddd;
      text-align: center;
    }
  }
  
  .ProcessTable {
    > tbody {
      > tr {
        > td {
          border: 1px solid #ddd;
          padding: 10px;
          text-align: center;
        }
  
      }
  
    }
  }
  
  .inwardCheckbox {
    width: 300px;
    padding: 10px;
    display: block;
    max-height: 150px;
    overflow-y: scroll;
    background-color: #fafafa;
  
  
    label {
      display: block;
      //  margin-left: 8px;
    }
  }
  
  .inwardRadioButton {
    display: inline-flex;
  }
  
@primary-color: #48c75b;