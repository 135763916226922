.textBlack {
  color: black;
}
.heading {
  //  backgroundColor: '#f0f2f5',
  height: 26px;
  padding-left: 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.addItem {
  //  padding: 30px;
  // padding-right: 30px;
  padding-left: 15px;
  text-align: center;
}

.addItemButton {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 32px;
}

.subTotal {
  white-space: nowrap;
  width: 40%;
  text-align: left;
}
.dataTable {
  border-collapse: collapse;
  width: 100%;

  th {
    font-size: 12px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  tr > td:first-child {
    font-weight: bold;
    width: 144px;
  }
  tfoot {
    font-weight: bold;
  }

  .totalRows {
    font-weight: bold;
  }
}
.notification {
  display: flex;
  justify-content: center;
}
.autoCompleteList {
  font-size: 12px;
  margin-top: -5px;
}

.checkbox {
  width: 300px;
  padding: 10px;
  display: block;

  label {
    display: block;
    margin-top: 5px;
  }
}

.vertical-dropdown .ant-select-dropdown-menu {
  display: block;
}

.vertical-dropdown .ant-select-dropdown-menu-item {
  display: block;
}

.vertical-option {
  display: block;
}

@primary-color: #48c75b;